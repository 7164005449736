import styled, { css } from 'app/theme';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  ReactStripeElements,
} from 'react-stripe-elements';

import { CardAuthorization } from './CardAuthorization'

export const FieldWrapper = styled.div`
  ${({ theme: { variables } }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${variables.margins.marginBig};
    width: 100%;

    label {
      font-size: ${variables.fonts.size.smaller1};
      text-transform: uppercase;
      color: ${variables.colors.gray};
      margin-bottom: ${variables.margins.marginMedium};
    }

    .field-validation {
      display: flex;
      flex-direction: column;

      span {
        color: ${variables.colors.red};
        font-size: ${variables.fonts.size.smaller1};
      }
    }

    .pay-input {
      padding: 17px 12px;
      background: ${variables.colors.neutral1};
      border: none;
      color: ${variables.colors.black};
      font-size: ${variables.fonts.size.smaller1};
      font-family: ${variables.typography.primaryFont};
      font-weight: 400;

      :focus {
        outline-width: 0;
        background: ${variables.colors.neutral2};
      }
    }

    .StripeElement--invalid {
      border: 1px solid ${variables.colors.red};
    }
  `}
`;

export const ColumnsWrapper = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -20px;
    width: 100%;

    > div {
      width: calc(50% - 15px);
    }
  `}
`;

export type CardDataComplete = {
  cvc: boolean;
  expirationDate: boolean;
  number: boolean;
};

export type CardInputFormProps = {
  name: string,
  checked: boolean,
  onCheckChange: (checked: boolean) => void
}

export const CardInputForm = ({ name, checked, onCheckChange }: CardInputFormProps) => {

  const [cardDataComplete, setCardDataComplete] = useState<CardDataComplete>({
    cvc: false,
    expirationDate: false,
    number: false,
  });
  
  const setCardDataField = useCallback(
    (field: keyof CardDataComplete) => ({ complete }: ReactStripeElements.ElementChangeResponse) => {
      setCardDataComplete((previousState: any) => ({
        ...previousState,
        [field]: complete,
      }));
    },
    [],
  );

  return (
    <Fragment>
      <FieldWrapper>
        <label>Card number</label>
        <CardNumberElement className="pay-input" placeholder="" onChange={setCardDataField('number')} />
      </FieldWrapper>
      <ColumnsWrapper>
        <FieldWrapper>
          <label>Expiration date</label>
          <CardExpiryElement className="pay-input" placeholder="" onChange={setCardDataField('expirationDate')} />
        </FieldWrapper>
        <FieldWrapper>
          <label>CVC</label>
          <CardCVCElement className="pay-input" placeholder="" onChange={setCardDataField('cvc')} />
        </FieldWrapper>
      </ColumnsWrapper>
      <div 
        style={{
          marginTop: '24px'
        }}>
      <CardAuthorization
        name={name}
        checked={checked}
        onCheckChange={onCheckChange} />
      </div>
    </Fragment>
  );
};
