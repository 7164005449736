import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { CardInputForm } from 'app/components/CardInputForm';
import { Button } from 'app/components/button/Button';

import { AddCardWrapper, Content } from '../styles/AddCardStyles';
import { UserContext } from 'app/context/UserContext';
import firebase from 'firebase/app';
import { useUserRepository } from 'app/repository/UserRepository';
import { BookingContext } from 'app/context/BookingContext';
import { getRandomId } from 'app/utils/utils';
import { ErrorView } from './ErrorView';
import { LoadingView } from './LoadingView';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import { catchError } from 'app/utils/analytics';

export interface AddCardProps extends ReactStripeElements.InjectedStripeProps {
  stylist: any;
  stylistId: string;
}

const AddCardComponent: FunctionComponent<AddCardProps> = ({ stripe }) => {

  const history = useHistory()
  const userRepo = useUserRepository()
  const userContext = useContext(UserContext)
  const bookingContext = useContext(BookingContext)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()
  const [isCardAuthorized, setIsCardAuthorized] = useState(true)

  if (userContext.isLoading) {
    return <LoadingView />
  }

  if (!firebase.auth().currentUser || !userContext.currentProfile) {
    return <>You are not signed in</>
  }

  function addCardToUserProfile(card: any) {

    // Update the current card
    let currentProfile = userContext.currentProfile
    const profiles = userContext.profiles.map(profile => {
      if (profile.id === userContext.currentProfile.id) {

        // Create card list if missing
        // This can happen if the user
        // was new and had no cards
        if (!profile.savedCards) {
          profile.savedCards = []
        }

        // Add the card
        profile.savedCards.push(card)
        profile.lastUsedSavedCard = card.id
        currentProfile = profile
        
      }
      return profile
    })

    // Post the updates
    userContext.setProfiles(profiles)
    userContext.setCurrentProfile(currentProfile)

    // Post the booking user updates
    const currentBookingProfile = bookingContext.profile
    if (currentBookingProfile) {
      bookingContext.setProfile(currentProfile)
    }

  }

  async function onSubmit(values: any) {
    try {

      setIsLoading(true)
      setError(null)

      // Ensure we have a stripe reference
      if (!stripe) {
        setError(catchError('add_card_stripe', 'Unable to save card'))
        return
      }

      // Ensure we have a token
      const stripeRes = await stripe.createToken()
      if (stripeRes.error) {
        setIsLoading(false)
        setError(stripeRes.error)
        catchError('add_card_stripe_token_res', 'stripe.createToken error')
        return
      }

      const token = stripeRes.token
      if (!token) {
        setIsLoading(false)
        setError(catchError('add_card_stripe_token', 'Unable to save card'))
        return
      }

      // Ensure values exist
      const card = token.card
      if (!card) {
        setIsLoading(false)
        setError(catchError('add_card_stripe_card', 'Unable to save card'))
        return
      }

      const { brand, exp_month, exp_year, last4 } = card

      // If we do not need to save the card
      // "Save" it locally
      // Otherwise, save the card to the user
      if (!isCardAuthorized) {

        addCardToUserProfile({
          brand: brand || '',
          expMonth: exp_month || 0,
          expYear: exp_year || 0,
          id: getRandomId('localcard_'),
          last4: last4 || '',
          stripePaymentSourceId: token.id,
        })

      } else {

        // Create the new card
        const res = await userRepo.postNewSavedCard(
          userContext.currentProfile.id, 
          token.id
        )

        addCardToUserProfile({
          brand: brand || '',
          expMonth: exp_month || 0,
          expYear: exp_year || 0,
          id: res.savedCardId,
          last4: last4 || '',
          stripePaymentSourceId: token.id,
        })

      }

      // Close the view
      history.goBack()

    } catch (error) {
      setIsLoading(false)
      setError(catchError('add_card_fallback', 'Unable to save card'))
    }
  }

  return (
    <Wrapper>
      {error && <ErrorView message={error.message} />}
      {isLoading && <LoadingView />}
      <AddCardWrapper hidden={isLoading}>
        <Form
          onSubmit={onSubmit}>
          {({ handleSubmit }) => {
            return (
              <form action="" className="add-new-card-form" id="add-new-card" onSubmit={handleSubmit}>
                <Content>
                  <CardInputForm 
                    name="cardAuthorized"
                    checked={isCardAuthorized}
                    onCheckChange={(checked => {
                      setIsCardAuthorized(checked)
                    })} />
                  <Button 
                    type="submit" 
                    style={{
                      width: '100%',
                      marginTop: '24px',
                      marginBottom: '24px'
                    }}>
                    Save
                  </Button>
                </Content>
              </form>
            );
          }}
        </Form>
      </AddCardWrapper>
    </Wrapper>
  );
};

export const AddCard = injectStripe(AddCardComponent);
